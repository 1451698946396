/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta, SideBySide} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you buy ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "new hearing aids"), ", you want to be sure that your money is well spent. Thankfully, with a ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aids brand"), " like Rexton, you don’t have to worry about the quality and technology in your devices, one of the world’s most trustworthy hearing aid manufacturers."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rexton-a-half-century-of-excellence",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rexton-a-half-century-of-excellence",
    "aria-label": "rexton a half century of excellence permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rexton: A Half-Century of Excellence"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rexton, a subsidiary of Sivantos, has been a leading manufacturer of high-quality hearing aids for over half a century. These days, Rexton’s products are produced by Sivantos and are available in more than 70 countries around the world, making it one of the few truly global hearing aid manufacturers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rexton prides itself on creating efficient and seamless interfaces so that their hearing aids are accessible to everyone. As a brand that’s consistently at the forefront of hearing aid research and development, Rexton boasts a number of industry firsts, including the first BTE (behind the ear) hearing aid as well as the first ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth"), " and the first waterproof hearing aids. If that wasn’t good enough, thanks to their broad range of products, there’s a Rexton hearing aid out there for everyone."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-hearing-aid-you-can-trust",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-hearing-aid-you-can-trust",
    "aria-label": "a hearing aid you can trust permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Hearing Aid You Can Trust"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As a leader in the hearing aid industry for over sixty years, Rexton is dedicated to creating high-quality hearing aids that meet every individual’s needs. They believe that everyone should be able to hear to the best of their abilities in any environment, which is why their devices can be personalized to achieve unprecedented levels of wearing comfort. Supported by the latest and greatest in hearing aid technology, Rexton’s products make living to your fullest a breeze."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-better-with-rexton",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-better-with-rexton",
    "aria-label": "hearing better with rexton permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Better with Rexton"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rexton’s innovative technology is available in three main product lines, each of which supports a different kind of hearing aid wearer and a different hearing need. Whether you like the natural sound and direct streaming capabilities of the MyCore line, the sound-enhancing technology of TruCore line, or the budget-friendliness of the Essential E1 line, there’s a Rexton hearing aid to meet your needs. Let’s take a look at some of the technology that makes Rexton’s hearing aid so great:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "smartphone-apps-and-bluetooth-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#smartphone-apps-and-bluetooth-connectivity",
    "aria-label": "smartphone apps and bluetooth connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Smartphone Apps and Bluetooth Connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes to personalization of your hearing aids, Rexton knows that one-size just doesn’t fit all. Thankfully, Rexton’s Bluetooth-supported smartphone app allows you to discreetly adjust your hearing aids on-the-go. Their free apps let you adjust your hearing aids’ volume, microphone directionality, check in on your battery status, and much more! Oh, and you can stream directly from nearly any Bluetooth-supported device, right to your hearing aids. What’s not to love?"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "xphone-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#xphone-technology",
    "aria-label": "xphone technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "XPhone Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If that wasn’t good enough, many of Rexton’s hearing aids are built with XPhone technology, which simultaneously sends the noise signals of your phone to both ears to help you make the most of your conversation."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "my-voice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#my-voice",
    "aria-label": "my voice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "My Voice"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "No one likes to feel like their voice is being unnaturally amplified. Thanks to Rexton’s My Voice technology, you don’t have to ever again. My Voice helps identify each hearing aid wearer’s own voice and processes it separately to keep it distinct from environmental sounds, providing a more natural listening experience, every time."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "voice-ranger",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#voice-ranger",
    "aria-label": "voice ranger permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Voice Ranger"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever tried to have a conversation in a noisy environment, only to feel like your hearing aids were amplifying every little noise? When you use Rexton’s Voice Ranger-capable hearing aids, you can be confident that your devices will maintain the natural loudness of the other half of your conversation, making it easier to have a one-on-one interaction in a noisy place."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "tinnitus-function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tinnitus-function",
    "aria-label": "tinnitus function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tinnitus Function"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tinnitus is an all-too-common condition that affects millions of people around the world. While some people find that their tinnitus has little impact on their lives, the ringing in the ears that’s associated with tinnitus can make it difficult for many to hear, sleep, and concentrate. With Rexton’s Tinnitus Function technology, however, a built-in white sound generator helps soften these unwanted noises for on-the-go tinnitus relief."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "in-the-news",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-news",
    "aria-label": "in the news permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In the News"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Recently, Rexton celebrated its 60th anniversary as a leader in the hearing aid industry by creating a $60,000 educational scholarship designed to benefit current and future students pursuing a degree as a doctor of audiology. Each year, winners are selected from a pool of undergraduate and graduate students that have been accepted to a full-time AuD program and who have met Rexton’s rigorous academic performance criteria. Students who are selected for the scholarship receive financial support for their education as they study to become the next leaders in the hearing healthcare field. Way to go, Rexton!"), "\n", React.createElement(LandingPageCta, {
    copy: "Try a Rexton device now!",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rexton-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rexton-hearing-aids",
    "aria-label": "rexton hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rexton Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/rexton-inox.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rexton-inox",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rexton-inox",
    "aria-label": "rexton inox permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rexton inoX"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Changes occur in the brain when you acquire hearing loss. Auditory neurons need continuous input to stay healthy. For that reason, many hearing care professionals recommend treating hearing loss sooner rather than later. On average, people wait about seven years to seek an evaluation after they start to notice hearing difficulties."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/rexton/inox",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/rexton-stellar.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rexton-stellar",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rexton-stellar",
    "aria-label": "rexton stellar permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rexton Stellar"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Consumers have been asking for the convenience of rechargeable hearing aids. Conventional hearing aids operate on disposable zinc air batteries that last about a week. People find it hard to remember to carry around an extra pack of batteries. The tiny batteries can be challenging to remove and replace."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/rexton/stellar",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/rexton-sterling.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rexton-sterling",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rexton-sterling",
    "aria-label": "rexton sterling permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rexton Sterling"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with hearing loss have more difficulty understanding speech in noisy environments. The auditory system can no longer filter out noise as well. Patients also report that noisy places annoy and distract them."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/rexton/sterling",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/rexton-emerald.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rexton-emerald",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rexton-emerald",
    "aria-label": "rexton emerald permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rexton Emerald"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Rexton Emerald series hearing aids are everything you need in a hearing device. It’s small, powerful, and everlasting! There’s no reason to be conflicted about which aid to use. The Emerald is designed for your convenience and comfort."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/rexton/emerald",
    className: "c-md-a"
  }, "Read more"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
